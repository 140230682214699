import React, {Component} from 'react';
import {Link} from 'react-router-dom';

// Layout
import Header from "../layout/header2";
import Footer from "../layout/footer";

// Elements
// import AboutSection from "../elements/about";
// import LatestNewsSection from "../elements/latest-news-slider";
// import FeatureSection3 from "../elements/feature-section3";
// import TeamSection from "../elements/team";
// import TestimonialSection from "../elements/testimonial";

// Import Images
import bnrImg1 from "../../images/banner/img1.jpg";
import waveBlue from "../../images/shap/wave-blue.png";
import circleDots from "../../images/shap/circle-dots.png";
import plusBlue from "../../images/shap/plus-blue.png";

class ImprovingYourFinancialHealthWithProfitEnhancementServices extends Component{
	
	render(){
		return (
			<>
				
				<Header />
				
				<div className="page-content bg-white aboutus">					
					<div className="banner-wraper">
						<div className="page-banner" style={{backgroundImage: "url("+bnrImg1+")"}}>
							<div className="container">
								<div className="page-banner-entry text-center">
									<h2>Improving Your Financial Health with Profit Enhancement Services</h2>
									<nav aria-label="breadcrumb" className="breadcrumb-row">
										<ul className="breadcrumb">
											<li className="breadcrumb-item"><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg> Home</Link></li>
											<li className="breadcrumb-item active" aria-current="page">Improving Your Financial Health with Profit Enhancement Services</li>
										</ul>
									</nav>
								</div>
							</div>
							<img className="pt-img1 animate-wave" src={waveBlue} alt=""/>
							<img className="pt-img2 animate2" src={circleDots} alt=""/>
							<img className="pt-img3 animate-rotate" src={plusBlue} alt=""/>
						</div>
					</div>

					<section>
						<div className="container">
							<div className="row justify-content-center">
								{/* <div className="col"></div> */}
								<div className="col-md-10">
									<div className="heading-bx text-center">
										<h3 className="title">Improving Your Financial Health with Profit Enhancement Services</h3>
									</div>
									<p>
									Healthcare is an industry that is constantly evolving, and change is the only constant. The forms you are required to complete this month may become obsolete next month, and HMO's and PPO's can significantly affect your practice's earning potential. Sometimes, it may seem like the harder you work, the less you make. While Key MedSolutions can't change healthcare regulations, they can help you receive every dollar you are entitled to. As your revenue cycle management partner, they review and update your fee schedules annually by payor, ensuring that you are being paid based on your contracted rates. They load fee schedule reimbursement rates into their practice management software at the CPT code level to make sure you are accurately paid. Additionally, they identify the time limit for filing and the time allotted to appeal a claim for each payor that you participate in. They handle this analysis annually, keeping you up to date with the payment rules and informed if your payors reduce your reimbursement rates. Key MedSolutions works with you to increase your revenue and maximize your profit.
									</p>									
								</div>
								{/* <div className="col"></div> */}
							</div>
						</div>
					</section>
					
					
					{/* <AboutSection /> 
					
					<FeatureSection3 />
					
					<TeamSection />
					
					<TestimonialSection />
					
					<LatestNewsSection /> */}
				
				</div>
				
				<Footer />
				
			</>
		);
	}
}

export default ImprovingYourFinancialHealthWithProfitEnhancementServices;